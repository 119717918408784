import React, { FunctionComponent } from "react"
import LayoutWithMap from "../components/LayoutWithMap"

interface OwnProps {}

type Props = OwnProps

const Index: FunctionComponent<Props> = props => {
  return (
    <LayoutWithMap mapLocation={"Oklahoma"} mapZoomLevel={9}>
      <div className={"flex flex-col justify-around lg:w-1/2 p-4 text-lg"}>
        <div>
          <p>
            Tote-A-Poke convenience stores offer a wide selection of convenience
            items, including:
          </p>
          <ul className={"list-disc list-inside font-bold"}>
            <li>non-ethanol gasoline</li>
            <li>diesel</li>
            <li>deli-food items</li>
            <li>money orders</li>
          </ul>
          <p className={"mt-4 lg:mt-8"}>
            There are nine stores located in LeFlore County and one located in
            Haskell County.
          </p>
        </div>
      </div>
    </LayoutWithMap>
  )
}

export default Index
